































































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Throttler from '@/misc/Throttler';
import {calendarCoordsConverting, createCoordinates} from '@/misc/helperMethods';
import {namespace} from 'vuex-class';
import {calendarStoreGetter, calendarStoreMutations} from '@/store/calendar.store';
import {CalendarItemInterface} from '@/interfaces/CalendarItem.interface';
import SlotGroup from '@/models/SlotGroup.model';
import {SlotType} from '@/enums/SlotType.enum';
import {
  CALENDAR_DATE_ITEM_WIDTH,
  CALENDAR_ITEM_HEIGHT,
  CALENDAR_ITEM_WIDTH,
  LIST_BENCH_VALUE,
  WEEK_NUMBER_CONTROLS
} from '@/components/calendar/misc/calendar.config';
import Slot from '@/models/Slot.model';
import SlotBookingRequest from '@/interfaces/SlotBookingRequest.interface';
import {CalendarContextMenuEvents} from '@/interfaces/CalendarContextMenuEvents.interface';
import {ShiftConfig} from '@/interfaces/ShiftConfig.interface';
import {slotStoreActions} from '@/store/slot.store';
import {ShiftType} from '@/enums/ShiftType.enum';
import {DateTime} from 'luxon';
import {UserRole} from '@/models/User.model';

const CalendarStore = namespace('calendar');
const SlotStore = namespace('slot');

/**
 * Calendar component which contains the calendar logic.
 */
@Component({
  components: {
    CalendarItemDateComponent: () => import(
        /* webpackChunkName: "CalendarItemDateComponent" */
        '@/components/calendar/items/CalendarItemDate.component.vue'),
    CalendarItemRestrictionComponent: () => import(
        /* webpackChunkName: "CalendarItemRestrictionComponent" */
        '@/components/calendar/items/CalendarItemRestriction.component.vue')
  }
})
export default class CalendarComponent extends Vue {

  /**
   * The slotGroups to fill the calendar
   */
  @Prop({default: () => []})
  public slotGroups!: SlotGroup[];

  /**
   * The slotGroups to fill the calendar
   */
  @Prop()
  public shiftConfig!: ShiftConfig;

  /**
   * Describes the context menu component which is used
   */
  @Prop()
  public contextMenuComponent!: CalendarContextMenuEvents<any>;

  /**
   * Describes the calendar item component which is used
   */
  @Prop()
  public calendarItemComponent!: CalendarItemInterface;

  @Prop({default: false})
  public isLoading!: boolean;

  /**
   * The min date when an interaction for non admin user is possible
   */
  @Prop()
  public minDate!: DateTime;

  /**
   * The max date when an interaction for non admin user is possible
   */
  @Prop()
  public maxDate!: DateTime;

  // -- Config values
  public LIST_BENCH_VALUE = LIST_BENCH_VALUE;
  public CALENDAR_ITEM_HEIGHT = CALENDAR_ITEM_HEIGHT;

  public calendarFullHeight = '0px';
  public calendarFullWidth = '0px';
  private throttler: Throttler = new Throttler();
  public maxSlots: number = 0;
  public isReloading: boolean = true;

  // -- Keyboard vars
  private isShiftPressed = false;
  private isControlPressed = false;

  @CalendarStore.Mutation(calendarStoreMutations.SET_SELECTION_REFERENCE)
  private setSelectionReference!: (payload: string) => void;

  @CalendarStore.Mutation(calendarStoreMutations.UPDATE_SELECTION)
  private updateSelection!: (payload: string) => void;

  @CalendarStore.Mutation(calendarStoreMutations.CLEAR_SELECTION)
  private clearSelection!: () => void;

  @CalendarStore.Mutation(calendarStoreMutations.CLEAR_STORE)
  private clearStore!: () => void;

  @SlotStore.Action(slotStoreActions.INCREASE_EXTRA_SLOTS)
  public increaseExtraSlots!: (payload: { shift: ShiftType, slotType: SlotType, date: string }) => Promise<SlotGroup>;

  @SlotStore.Action(slotStoreActions.DECREASE_EXTRA_SLOTS)
  public decreaseExtraSlots!: (payload: { shift: ShiftType, slotType: SlotType, date: string }) => Promise<SlotGroup>;

  // Necessary assignment to enable access to this method in template
  public createCoordinates = createCoordinates;

  public SlotType = SlotType;
  public UserRole = UserRole;

  // Diverse event listener
  private windowResizeEventListener: () => void = () => {
    this.setCalendarHeight();
    this.setCalendarFullWidth()
  };
  private windowKeyDownEventListener: (event: KeyboardEvent) => void = (event: KeyboardEvent) => this.onKeyDown(event);
  private windowKeyUpEventListener: (event: KeyboardEvent) => void = (event: KeyboardEvent) => this.onKeyUp(event);

  public mounted() {
    // Reset calendar store
    this.clearStore();

    this.setCalendarHeight();
    window.addEventListener('resize', this.windowResizeEventListener);
    // eslint-disable-next-line
    window.addEventListener('keydown', this.windowKeyDownEventListener);
    window.addEventListener('keyup', this.windowKeyUpEventListener);

    this.isReloading = true;

    this.$nextTick(() => {
      this.isReloading = false;
    });
  }

  public beforeDestroy() {
    window.removeEventListener('resize', this.windowResizeEventListener);
    window.removeEventListener('keydown', this.windowKeyDownEventListener);
    window.removeEventListener('keyup', this.windowKeyUpEventListener);
  }

  /**
   *  Watch for slotGroups changes and handle them!
   */
  @Watch('slotGroups', {immediate: true})
  private onSlotGroupsChange() {
    this.clearStore();
    // Determine max slots for x scale at the top of calendar
    const availableSlotsArray = this.slotGroups.map(item => item.maxAvailableSlots!);
    if (availableSlotsArray.length > 0) {
      this.maxSlots = Math.max.apply(null, availableSlotsArray);
    }

    // Determine Boundaries
    const boundaries: { xMin: number, xMax: number }[] = [];
    this.slotGroups.forEach(slotGroup => {
      // car boundaries for this slot plus consider min/max value for shift type
      boundaries.push({
        xMin: slotGroup.bookedCarSlotsCount!,
        xMax: slotGroup.bookedCarSlotsCount! + slotGroup.availableSlots.CAR
      });
      // heavy boundaries for this slot plus consider min/max value for shift type
      boundaries.push({
        xMin: slotGroup.bookedHeavySlotsCount!,
        xMax: slotGroup.bookedHeavySlotsCount! + slotGroup.availableSlots.HEAVY
      });
    });

    this.setBoundaries(boundaries);

    // Determine occupied slots
    this.slotGroups.forEach((slotGroup: SlotGroup, yIndex: number) => {
      slotGroup.bookedSlots.cars
          .forEach((slot: Slot, xIndex: number) => {
            this.addOccupied({
              coords: `${xIndex}-${yIndex * 2}`,
              data: slot
            });
          });
      slotGroup.bookedSlots.heavy
          .forEach((slot: Slot, xIndex: number) => {
            this.addOccupied({
              coords: `${xIndex}-${yIndex * 2 + 1}`,
              data: slot
            });
          });
    });
    this.setCalendarFullWidth();
  }

  public setCalendarFullWidth() {
    // this.maxSlots * CALENDAR_ITEM_WIDTH  -> normal width of cells
    // CALENDAR_DATE_ITEM_WIDTH -> consider date item with
    // (CALENDAR_ITEM_WIDTH * 2) -> consider restriction item and increase / decrease item width
    // 12 -> offset for scrollbar
    // Width based on slot amount
    const dynamicFullWidth = this.maxSlots * CALENDAR_ITEM_WIDTH + CALENDAR_DATE_ITEM_WIDTH + (CALENDAR_ITEM_WIDTH * 2) + 12;
    // Width based on viewport width
    const staticFullWidth = document.documentElement.clientWidth - CALENDAR_DATE_ITEM_WIDTH + 63; // I don know why 63... but it works! :D
    // The bigger of the two has to be selceted
    this.calendarFullWidth = `${Math.max(dynamicFullWidth, staticFullWidth)}px`;
  }

  public get calendarHeaderElement(): HTMLDivElement {
    return this.$refs['calendar-header'] as HTMLDivElement;
  }

  public get virtualScrollElement(): HTMLDivElement & Vue {
    return this.$refs['virtual-scroll'] as HTMLDivElement & Vue;
  }

  @CalendarStore.Mutation(calendarStoreMutations.SET_SHOW_CONTEXT_MENU)
  private setShowContextMenu!: (value: boolean) => void;

  @CalendarStore.Mutation(calendarStoreMutations.SET_CONTEXT_MENU_POSITION)
  private setContextMenuPosition!: (payload: { x: number, y: number }) => void;

  @CalendarStore.Mutation(calendarStoreMutations.ADD_OCCUPIED)
  private addOccupied!: (payload: { coords: string, data: any }) => void;

  @CalendarStore.Getter(calendarStoreGetter.GET_OCCUPIED)
  private getOccupied!: (coords: string) => any | undefined;

  @CalendarStore.Mutation(calendarStoreMutations.SET_BOUNDARIES)
  private setBoundaries!: (payload: { xMin: number, xMax: number }[]) => void;

  @CalendarStore.Getter(calendarStoreGetter.GET_BOUNDARY)
  private getBoundary!: (index: number) => { xMin: number, xMax: number };

  @CalendarStore.Getter(calendarStoreGetter.GLOBAL_SELECTION)
  private _globalSelection!: Set<string>;

  private get globalSelection(): Set<string> {
    return this._globalSelection;
  }

  private globalSelectionChangeCache: string | null = null; // TODO improve
  @Watch('globalSelection', {immediate: true})
  private onGlobalSelectionChange() {
    const selectionArr = [...this.globalSelection];
    if (this.globalSelectionChangeCache != selectionArr[selectionArr.length - 1]) {
      this.globalSelectionChangeCache = selectionArr[selectionArr.length - 1];
      this.determineSelectionInformation([...this.globalSelection]);
    }
  }

  @CalendarStore.Getter(calendarStoreGetter.ITEM_FOCUSSED)
  private _itemFocussed!: number[];

  public get itemFocussed(): number[] {
    return this._itemFocussed;
  }

  @CalendarStore.Mutation(calendarStoreMutations.SET_ITEM_FOCUSSED)
  private setItemFocussed!: (payload?: string) => void;

  @CalendarStore.Mutation(calendarStoreMutations.ADD_SELECTION_ITEM)
  private addSelectionItem!: (payload: string) => void;

  @CalendarStore.Mutation(calendarStoreMutations.SET_SHOW_CREATE_CONTEXT_MENU)
  private setShowCreateContextMenu!: (payload: boolean) => void;

  @CalendarStore.Getter(calendarStoreGetter.IS_CONTEXT_MENU_ACTIVE)
  private _isContextMenuActive!: boolean;

  public get isContextMenuActive(): boolean {
    return this._isContextMenuActive;
  }

  @CalendarStore.Mutation(calendarStoreMutations.SET_SELECTION_INFORMATION)
  private setSelectionInformation!: (value: undefined | Partial<SlotBookingRequest>) => void;

  @CalendarStore.Mutation(calendarStoreMutations.SET_SLOT_SELECTION_INFORMATION)
  private setSlotSelectionInformation!: (value: undefined | Partial<SlotBookingRequest>) => void;

  @CalendarStore.Getter(calendarStoreGetter.SELECTION_INFORMATION)
  private _selectionInformation!: undefined | Partial<SlotBookingRequest>;

  public get selectionInformation(): undefined | Partial<SlotBookingRequest> {
    return this._selectionInformation;
  }

  /**
   * Determines the SlotGroups which are associated with the selection
   */
  public determineSelectionInformation(selectionArr: string[]) {
    if (selectionArr.length === 0) {
      this.setSelectionInformation(undefined);
      return;
    }
    // If selection has just one entry - it is possible that a occupied slot is selected
    if (selectionArr.length === 1) {
      const occupied = this.getOccupied(selectionArr[0]) as Slot;
      if (occupied) {
        this.setSelectionInformation(occupied); // YEAH a occupied Slot was found!! Stop further search
        this.setSlotSelectionInformation(occupied);
        return;
      }
    }

    // No occupied slot was found - determine data for creation
    const selectionStart = selectionArr[0].split('-').map(item => Number(item));
    const selectionEnd = selectionArr[selectionArr.length - 1].split('-').map(item => Number(item));
    // Is for start and end the same, cause a selection can only be made in one row
    const yValue = selectionStart[1];
    // Determine slot group
    const slotGroup = this.slotGroups[Math.floor(yValue / 2)];
    this.setSelectionInformation({
      date: slotGroup.date!,
      shift: slotGroup.shift!,
      slotType: yValue % 2 === 0 ? SlotType.CAR : SlotType.HEAVY,
      amount: selectionEnd[0] - selectionStart[0] + 1
    });
    this.setSlotSelectionInformation(undefined);
  }


  /**
   * Support function to determine if column index should be highlighted
   */
  public highLightColumnIndex(index: number): boolean {
    return (this.selectionInformation?.amount ?? 0) > index;
  }

  /**
   * The blocked object assures that only on scrolling action per direction will be performed
   */
  private blockedScroll: { top: boolean, right: boolean, bottom: boolean, left: boolean, [key: string]: boolean } = {
    top: false,
    right: false,
    bottom: false,
    left: false
  };

  /**
   * Mirrors the scrolling of the calendar on the calendar header
   * @param event
   */
  public onCalendarScroll(event: Event) {
    this.calendarHeaderElement.scrollLeft = (event.target as HTMLDivElement).scrollLeft;
  }

  /**
   * Sets and calculates a calendar height
   */
  public setCalendarHeight() {
    this.throttler.throttle(() => {
      const value = window.innerHeight - this.$vuetify.application.top - WEEK_NUMBER_CONTROLS - 54;
      this.calendarFullHeight = `${value}px`;
    });
  }

  /**
   * Context menu event handler (show custom context menu)
   * @param event
   */
  public onContextMenuClick(event: MouseEvent) {
    this.setShowContextMenu(false);
    this.setContextMenuPosition({x: event.clientX, y: event.clientY});
    this.$nextTick(() => {
      this.setShowContextMenu(true);
    });
  }

  /**
   * Duoble click handler
   */
  public handleDbClick() {
    this.clearBaseStates();
    this.$emit('double-click');
  }

  public onKeyDown(event: KeyboardEvent) {
    switch (event.key) {
      case 'Shift':
        this.isShiftPressed = true;
        break;
      case 'Meta': // Mac
      case 'Control': // Windows
        this.isControlPressed = true;
        break;
      case 'ArrowRight':
      case 'ArrowLeft':
        // Do no do anything with arrow keys if context menu is active
        if (!this.isContextMenuActive) {
          this.handleFocusItemMove(event.key);
        }
        break;
      case 'Escape':
        this.clearBaseStates();
        break;
    }
  }

  public onKeyUp(event: KeyboardEvent) {
    switch (event.key) {
      case 'Shift':
        this.isShiftPressed = false;
        break;
      case 'Meta': // Mac
      case 'Control': // Windows
        this.isControlPressed = false;
        break;
    }
  }

  /**
   * Handle error key stroke
   * @param type
   * @param selection
   * @param anchorCoordinate
   * @private
   */
  private handleArrowKeyStroke(type: 'ArrowUp' | 'ArrowRight' | 'ArrowDown' | 'ArrowLeft', selection: Set<string>) {
    this.setSelectionReference([...selection][0]);
    const processedCoordinates = calendarCoordsConverting([...selection][0]);
    // determine selection externalPoints
    // Check if selection would pass over the boundaries
    switch (type) {
      case 'ArrowUp':
        processedCoordinates[1]--;
        break;
      case 'ArrowDown':
        processedCoordinates[1]++;
        break;
    }
    this.updateSelection(processedCoordinates.join('-'));
    this.$nextTick(() => {
      this.selectionIsUpdated();
    });
  }

  /**
   * Handle the moving of the focus item
   * @param type
   * @private
   */
  private handleFocusItemMove(type: 'ArrowUp' | 'ArrowRight' | 'ArrowDown' | 'ArrowLeft') {
    if (!this.itemFocussed) {
      return;
    }
    const arrayCoordinates = this.itemFocussed.slice();
    // Check if focussed item would pass over the boundaries
    switch (type) {
      case 'ArrowRight':
        arrayCoordinates[0]++;
        break;
      case 'ArrowLeft':
        arrayCoordinates[0]--;
        break;
    }
    const stringCoordinates = arrayCoordinates.join('-');
    this.selectCell(stringCoordinates);
  }

  /**
   * Add this cell to the selection
   */
  public selectCell(coordinates: string) {
    this.addSelectionItem(coordinates);
    this.setItemFocussed(coordinates);
  }

  /**
   * Scrolls to particular row based on the given date
   */
  public scrollToDate(date: string) {
    if (this.virtualScrollElement) {
      // calculate the scrolling distance
      const availableCalendarElements = document.querySelectorAll(`.v-virtual-scroll [id*="${date}"]`) as NodeListOf<HTMLDivElement>
      const dayIndices = Array
          .from(availableCalendarElements)
          .map((item) => Number(item.id.split('-').pop()));
      const targetIndex = Math.min(...dayIndices);
      // 2 / because the indices determination works with half rows
      const distance = targetIndex * CALENDAR_ITEM_HEIGHT / 2;
      this.virtualScrollElement.$el.scrollTop = distance;
    }
  }

  /**
   * Clears the base states
   * @private
   */
  private clearBaseStates() {
    this.clearSelection();
    this.setItemFocussed(undefined);
    this.setShowCreateContextMenu(false);
  }

  /**
   * Event handler function which is fired after selection update (movement) and scrolls the calendar viewport
   * in a way that the selection is completely visible
   */
  public selectionIsUpdated() {
    if (this.virtualScrollElement) {
      const virtualScrollBounding = this.virtualScrollElement.$el.getBoundingClientRect();
      const selectedCalendarItems = document.querySelectorAll('.v-virtual-scroll [class*="is-selected"]') as NodeListOf<HTMLDivElement>;

      // Find first visible element via boundary dimensions overlapping
      Array.from(selectedCalendarItems).forEach((element: HTMLDivElement) => {
        const elementBounding = element.getBoundingClientRect();

        // Moving out of view: TOP
        if (!this.blockedScroll.top && (elementBounding.top < virtualScrollBounding.top)) {
          this.virtualScrollElement.$el.scrollTop -= CALENDAR_ITEM_HEIGHT;
          this.blockedScroll.top = true;
        }
        // Moving out of view: RIGHT
        if (!this.blockedScroll.right && (elementBounding.right > virtualScrollBounding.right)) {
          this.virtualScrollElement.$el.scrollLeft += CALENDAR_ITEM_WIDTH;
          this.blockedScroll.right = true;
        }
        // Moving out of view: BOTTOM
        if (!this.blockedScroll.bottom && (elementBounding.bottom > virtualScrollBounding.bottom)) {
          this.virtualScrollElement.$el.scrollTop += CALENDAR_ITEM_HEIGHT;
          this.blockedScroll.bottom = true;
        }
        // Moving out of view: LEFT
        // The left scrolling stuff has to handled differently, because the date column is
        // covering a part of the virtualScrollElement bounding in responsive mode
        const headerLength = CALENDAR_ITEM_WIDTH * this.maxSlots;
        const isResponsiveModeHorizontal = virtualScrollBounding.width < headerLength;
        const responsiveDifference = isResponsiveModeHorizontal ? CALENDAR_ITEM_WIDTH : 0;

        if (!this.blockedScroll.left && (elementBounding.left < (virtualScrollBounding.left + responsiveDifference))) {
          this.virtualScrollElement.$el.scrollLeft -= CALENDAR_ITEM_WIDTH;
          this.blockedScroll.left = true;
        }
      });
      // Reset blocking valus
      Object.keys(this.blockedScroll).forEach(key => {
        this.blockedScroll[key] = false;
      });
    }
  }

  public async increaseExtraSlotClicked(slotGroup: SlotGroup, slotType: SlotType) {
    await this.increaseExtraSlots({shift: slotGroup.shift!, date: slotGroup.date!, slotType});
  }

  public async decreaseExtraSlotClicked(slotGroup: SlotGroup, slotType: SlotType) {
    await this.decreaseExtraSlots({shift: slotGroup.shift!, date: slotGroup.date!, slotType});
  }

}
